import React from "react";
import { useFormState } from "react-final-form";
import FinalFormSelectField from "../../../../components/form/Fields/FinalFormSelectField";
import FinalFormTextField from "../../../../components/form/Fields/FinalFormTextField";
import FileUploadField from "../../../../components/form/components/inputs/generic/FileUploadField/FileUploadField";
import FormGroup from "../../../../components/form/components/shared/FormGroup/FormGroup";
import MuiGrid from "../../../../components/shared/MuiGrid";
import GridItem from "../../../../components/shared/MuiGrid/GridItem";
import { yesNo } from "../../../../forms/_formQuestions/selectOptions";
import { VALIDATOR_REQUIRE } from "../../../../helpers/validators/inputValidators";

const AuctionBorrowerExperience = (props) => {
  const { values } = useFormState();
  return (
    <>
      <FormGroup label="Income Producing Properites" noMargin>
        <MuiGrid>
          {/* /1/ */}
          <GridItem mobile size={values.hasExperienceWithIncomeProducingProperties === "Yes" ? 6 : 12}>
            <FinalFormSelectField
              noMargin
              name={"hasExperienceWithIncomeProducingProperties"}
              label="Do you have experience?"
              items={yesNo}
              // validate={VALIDATOR_EXPERIENCE}
              validate={VALIDATOR_REQUIRE}
            />
          </GridItem>
          {values.hasExperienceWithIncomeProducingProperties === "Yes" && (
            <>
              <GridItem mobile size={6}>
                <FinalFormTextField
                  name={"numOfIncomeProducingPropertiesWithinTwoYears"}
                  label="How many in the last two years?"
                  validate={VALIDATOR_REQUIRE}
                  number
                />
              </GridItem>
            </>
          )}
        </MuiGrid>
      </FormGroup>
      <FormGroup label="Fix And Flip Properties">
        <MuiGrid>
          <GridItem mobile size={values.hasExperienceWithFixFlips === "Yes" ? 6 : 12}>
            <FinalFormSelectField
              name={"hasExperienceWithFixFlips"}
              label="Do you have experience?"
              // validate={VALIDATOR_EXPERIENCE}
              validate={VALIDATOR_REQUIRE}
              items={yesNo}
            />
          </GridItem>
          {values.hasExperienceWithFixFlips === "Yes" && (
            <>
              <GridItem mobile size={6}>
                <FinalFormTextField
                  name={"numOfFixFlipsWithinTwoYears"}
                  label="How many in the last two years?"
                  validate={VALIDATOR_REQUIRE}
                  number
                />
              </GridItem>
            </>
          )}
        </MuiGrid>
      </FormGroup>

      <FormGroup label="Auction Properties">
        <MuiGrid>
          <GridItem mobile size={values.hasExperiencePropertiesPurchasedAtAuction === "Yes" ? 6 : 12}>
            <FinalFormSelectField
              name={"hasExperiencePropertiesPurchasedAtAuction"}
              label="Do you have experience?"
              // validate={VALIDATOR_EXPERIENCE}
              validate={VALIDATOR_REQUIRE}
              items={yesNo}
            />
          </GridItem>

          {values.hasExperiencePropertiesPurchasedAtAuction === "Yes" && (
            <>
              <GridItem size={6}>
                <FinalFormTextField
                  name={"numAuctionPropertiesWithinTwoYears"}
                  label="How many in the last two years?"
                  validate={VALIDATOR_REQUIRE}
                  number
                />
              </GridItem>
            </>
          )}
          {/* {values.hasExperienceWithGroundUpConstruction === "Yes" && (
            <>
              <GridItem mobile size={6}>
                <FinalFormTextField
                  name={"numOfGroundUpConstructionProjectsWithinTwoYears"}
                  label="How many in the last two years?"
                  validate={VALIDATOR_REQUIRE}
                  number
                />
              </GridItem>
            </>
          )} */}
        </MuiGrid>
      </FormGroup>
      {/* <FormGroup label="Do you have any experience with the following:">
        <MuiGrid>
          <GridItem size={values.hasExperienceIncomeProducingProperties === "Yes" ? 6 : 12}>
            <FinalFormSelectField
              name={"hasExperienceIncomeProducingProperties"}
              label="Income producing properties"
              // label="Total Years Experience"
              items={yesNo}
              validate={VALIDATOR_REQUIRE}
            />
          </GridItem>

          {values.hasExperienceIncomeProducingProperties === "Yes" && (
            <>
              <GridItem size={6}>
                <FinalFormTextField
                  name={"IncomeProducingPropertiesWithinTwoYears"}
                  label="How many in the last two years?"
                  validate={VALIDATOR_REQUIRE}
                  number
                />
              </GridItem>
            </>
          )}
          <GridItem size={values.hasExperienceFixFlip === "Yes" ? 6 : 12}>
            <FinalFormSelectField
              name={"hasExperienceFixFlip"}
              label="Fix-and-flip properties"
              // label="Total Years Experience"
              items={yesNo}
              validate={VALIDATOR_REQUIRE}
            />
          </GridItem>
          {values.hasExperienceFixFlip === "Yes" && (
            <>
              <GridItem size={6}>
                <FinalFormTextField
                  name={"fixFlipsWithinTwoYears"}
                  label="How many in the last two years?"
                  validate={VALIDATOR_REQUIRE}
                  number
                />
              </GridItem>
            </>
          )}
          <GridItem size={values.hasExperiencePropertiesPurchasedAtAuction === "Yes" ? 6 : 12}>
            <FinalFormSelectField
              name={"hasExperiencePropertiesPurchasedAtAuction"}
              items={["Yes", "No"]}
              label="Properties purchased at auction"
            />
          </GridItem>

          {values.hasExperiencePropertiesPurchasedAtAuction === "Yes" && (
            <>
              <GridItem size={6}>
                <FinalFormTextField
                  name={"auctionPropertiesWithinTwoYears"}
                  label="How many in the last two years?"
                  validate={VALIDATOR_REQUIRE}
                  number
                />
              </GridItem>
            </>
          )}

          {values.yearsExperienceProducingProperties === 0 &&
            values.flipsInLastThreeYears === 0 &&
            values.groundUpConstructionProjectsInLastThreeYears === 0 &&
            values.hasPurchasedPropertiesAtAuction === "No" && (
              <>
                <GridItem size={12}>
                  <Field
                    name={"hasTwoPlusYearsExperienceOfRealEstate"}
                    verticalOptions
                    multiple
                    label="Select Positions"
                    fieldLabel="Do you have 2+ years experience of any of the following positions?"
                    items={[
                      "A real estate agent",
                      "Real estate finance/legal professional",
                      "Mortgage loan originator",
                      "General contractor",
                      "Other (Any other capacity that provides direct experience and understanding of real estate valuation, market trends and/or costs of owning investment properties)",
                    ]}
                    component={MultiSelectFieldComponent}
                  />
                </GridItem>

                <GridItem size={12}>
                  <Field
                    name={"statesWithFinanceExperienceAndInterest"}
                    fieldLabel="What states do you have experience in or are you interested in financing transactions?"
                    label="Select States"
                    items={usaStates}
                    validate={VALIDATOR_REQUIRE}
                    multiple
                    helperText="Select all applicable states"
                    component={MultiSelectFieldComponent}
                  />
                </GridItem>
              </>
            )}
        </MuiGrid>
      </FormGroup> */}
      <FormGroup label="Proof of Experience">
        <MuiGrid>
          <GridItem size={12}>
            <FileUploadField
              name={"experienceDocs"}
              helperText="The more detail you provide, the faster we can approve"
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
    </>
  );
};

export default AuctionBorrowerExperience;
