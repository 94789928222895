export const formFormatDollar = (val) => {
  // console.log("val", val);
  let stringVal = String(val);
  let num = Intl.NumberFormat("en-US").format(val);
  // let stringVal = String(val);
  // let num = Intl.NumberFormat("en-US").format(stringVal);
  let formattedVal = num;
  // let formattedVal = `$${num}`;
  // console.log("formattedVal", formattedVal);
  // return formattedVal;
  // console.log("formattedVal", formattedVal);
  return isNaN(val) ? "" : formattedVal;
};
