import React from "react";
import "./Card.css";

const Card = (props) => {
  return (
    <div
      style={props.style}
      id={props.id}
      className={`card ${props.className}`}
    >
      {props.children}
    </div>
  );
};

export default Card;
