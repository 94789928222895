import React from "react";
import { Field, useFormState } from "react-final-form";
import FinalFormSelectField from "../../../../components/form/Fields/FinalFormSelectField";
import FinalFormTextField from "../../../../components/form/Fields/FinalFormTextField";
import { TextFieldComponent } from "../../../../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import AddressAutofillField from "../../../../components/form/components/inputs/generic/AddressAutofillField/AddressAutofillField";
import DobField from "../../../../components/form/components/inputs/generic/DobField";
import FileUploadField from "../../../../components/form/components/inputs/generic/FileUploadField/FileUploadField";
import RadioField from "../../../../components/form/components/inputs/generic/RadioField/RadioField";
import FormGroup from "../../../../components/form/components/shared/FormGroup/FormGroup";
import MuiGrid from "../../../../components/shared/MuiGrid";
import GridItem from "../../../../components/shared/MuiGrid/GridItem";
import { fileDocumentSchema } from "../../../../forms/_formQuestions/_formSchema/fileDocumentSchema";
import { citizenshipOptions } from "../../../../forms/_formQuestions/selectOptions";
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from "../../../../helpers/validators/inputValidators";

const AuctionContactInfo = (props) => {
  const { values } = useFormState();
  const normalizePhone = (value) => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length <= 3) return onlyNums;
    if (onlyNums.length <= 7) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`;
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
  };
  return (
    <>
      <FormGroup noMargin label="Your Information">
        <MuiGrid>
          <GridItem size={6}>
            <FinalFormTextField validate={VALIDATOR_REQUIRE} name={"firstName"} label="First Name" />
          </GridItem>
          <GridItem size={6}>
            <FinalFormTextField validate={VALIDATOR_REQUIRE} name={"lastName"} label="Last Name" />
          </GridItem>
          <GridItem size={12}>
            <FinalFormTextField name="emailAddress" label="Email Address" validate={VALIDATOR_EMAIL} />
          </GridItem>
          <GridItem mobile size={8}>
            <Field
              name="phoneNumber"
              label="Mobile Phone Number"
              parse={normalizePhone}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem mobile size={4}>
            <FinalFormSelectField
              name="allowPhoneNumberContact"
              label="Enroll in SMS Messaging"
              parse={normalizePhone}
              items={["Yes", "No"]}
            />
          </GridItem>
          {/* <GridItem size={6}>
            <FinalFormSelectField
              validate={VALIDATOR_REQUIRE}
              noMargin
              name={"isMarried"}
              items={[
                { name: "true", label: "Married" },
                { name: "false", label: "Not Married" },
              ]}
              // items={["Married", "Not Married"]}
              label="Marriage Status"
            />
          </GridItem> */}
          <GridItem size={12}>
            <FinalFormSelectField
              validate={VALIDATOR_REQUIRE}
              noMargin
              // isRequired
              name={"citizenshipStatus"}
              items={citizenshipOptions}
              label="Citizenship Status"

              // component={TextFieldComponent}
            />
          </GridItem>
          {/* <GridItem size={6}>
            <FinalFormSelectField
              name={`estimatedCreditScore`}
              label="Estimated Credit Score"
              helperText="(If not a foreign national) Your estimated credit score"
              validate={VALIDATOR_REQUIRE}
              items={estimatedCreditScore}
            />
          </GridItem> */}
        </MuiGrid>
      </FormGroup>
      <DobField />
      <AddressAutofillField
        isRequired
        noMargin={false}
        name={"primaryAddress"}
        label="Primary Home Address"
        value={values.primaryAddress}
      />

      <FormGroup label="Upload Photo Identification">
        <MuiGrid>
          <GridItem size={12}>
            <FileUploadField
              bold
              isRequired
              name={"photoId"}
              helperText="Please ensure form of identification is recent and valid"
              docGroup={fileDocumentSchema.borrowerDocs.name}
              docType={fileDocumentSchema.borrowerDocs.types.photoId.name}
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
    </>
  );
  return (
    <>
      <FormGroup label="Your Information">
        <MuiGrid>
          <GridItem size={6}>
            <FinalFormTextField validate={VALIDATOR_REQUIRE} name={"firstName"} label="First Name" />
          </GridItem>
          <GridItem size={6}>
            <FinalFormTextField validate={VALIDATOR_REQUIRE} name={"lastName"} label="Last Name" />
          </GridItem>
        </MuiGrid>

        <FinalFormTextField validate={VALIDATOR_REQUIRE} name={"ssn"} label="Social Security Number" />
        <FinalFormTextField name="emailAddress" label="Email Address" validate={VALIDATOR_EMAIL} />
        <Field name="phoneNumber" label="Phone Number" parse={normalizePhone} component={TextFieldComponent} />
      </FormGroup>

      <AddressAutofillField
        isRequired
        name={"primaryAddress"}
        fieldLabel="Primary Home Address"
        value={values.primaryAddress}
      />
      <DobField />
      {/* <MultipleEmailFields name={"emailAddress"} fieldLabel="Email Address" /> */}
      {/* <MultiplePhoneNumberFields
        name={"phoneNumber"}
        fieldLabel="Phone Number"
      /> */}

      <RadioField
        name={"citizenshipStatus"}
        items={citizenshipOptions}
        fieldLabel="Citizenship Status"
        // vertical
      />
      <FinalFormSelectField
        isRequired
        name={"isMarried"}
        items={["Married", "Not Married"]}
        fieldLabel="What is your marital status?"
      />

      <FileUploadField
        name={"photoId"}
        fieldLabel="Upload a Photo ID"
        docGroup={fileDocumentSchema.borrowerDocs.name}
        docType={fileDocumentSchema.borrowerDocs.types.photoId.name}
        maxFiles={1}
      />
    </>
  );
};

export default AuctionContactInfo;
