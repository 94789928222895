import React from "react";
// import './ErrorMessage.css';

const ErrorMessage = (props) => {
  return props.show ? (
    <div
      style={{
        paddingTop: "10px",
        color: "var(--red)",
        textAlign: "center",
        fontWeight: props.bold ? 700 : 500,
        ...props.style,
      }}
      className="error"
    >
      {props.message}
    </div>
  ) : null;
};

export default ErrorMessage;
