import { CircularProgress } from "@mui/material";
import React from "react";
import "./FullPageLoader.css";

const FullPageLoader = (props) => {
  return (
    <div className="full-page-loader-wrapper">
      <CircularProgress />
    </div>
  );
};

export default FullPageLoader;
