import { Box, Divider, Slide, Typography, Zoom } from "@mui/material";
import { isNil } from "lodash";
import React, { useState } from "react";
import { useFormState } from "react-final-form";

import SuccessContent from "../../../components/shared/SuccessContent";
import CalendarInviteForm from "./CalendarInviteForm";
import CalendarSectionWrapper from "./CalendarSectionWrapper";
import CalendarInviteTitleText from "./components/CalendarInviteTitleText";
import CalendarTimeSlotPicker from "./components/CalendarTimeSlotPicker";
import TimezoneToggle from "./components/TimezoneToggle";
import { convertTimezone } from "./helpers/convertTimezone";
import { formatISODate } from "./helpers/formatISODate";
import { getTimeFromISO } from "./helpers/getTimeFromISO";
import CalendarInviteLabelAndValue from "./components/CalendarInviteLabelAndValue";
import { isMobile } from "react-device-detect";
import { timezoneOptions } from "./timezoneOptions";
import { useMemo } from "react";
import { useEffect } from "react";

const center = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const centerCol = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

const column = {
  display: "flex",
  flexDirection: "column",
};
const flex = {
  display: "flex",
};

const CalendarInviteMain = ({ showSuccess, setShowSuccess, dates }) => {
  // const [showSuccess, setShowSuccess] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [initLoad, setInitLoad] = useState(false);
  const { values } = useFormState();

  const containerRef = React.useRef(null);

  const displayFormContent = useMemo(() => showForm && !isNil(values?.meetingDateTime), [showForm, values]);
  if (showSuccess) {
    return <FormSuccessContent showSuccess={showSuccess} />;
  }
  return (
    <Box
      // ref={containerRef}
      sx={{
        //
        ...column,
        flexGrow: 0,
        flexBasis: "665px",

        padding: isMobile ? "30px 20px" : `20px 20px ${showForm ? "20px" : "0"} 20px`,
      }}
    >
      <SectionHeader>
        <Box sx={{ ...flex, gap: "6px", flexWrap: "wrap" }}>
          <SectionTitle>{formatISODate(values?.meetingDate)}</SectionTitle>
          {isNil(values?.meetingDateTime) ? null : (
            <>
              <SectionTitle>{"@"}</SectionTitle>
              <SectionTitle>
                {isNil(values?.meetingDateTime)
                  ? null
                  : getTimeFromISO(convertTimezone(values?.meetingDateTime, values?.clientSelectedTimezone))}
              </SectionTitle>
              <SectionTitle>
                {isNil(values?.meetingDateTime) ? null : timezoneOptions[values?.clientSelectedTimezone]}
              </SectionTitle>
            </>
          )}
        </Box>
      </SectionHeader>

      {displayFormContent ? (
        <CalendarInviteForm
          setShowSuccess={(passed) => setShowSuccess(passed)}
          showForm={showForm}
          setShowForm={setShowForm}
          show={displayFormContent}
          container={containerRef.current}
        />
      ) : (
        <TimePickerContent
          dates={dates}
          setShowForm={setShowForm}
          show={!displayFormContent}
          container={containerRef.current}
          initLoad={initLoad}
          setInitLoad={setInitLoad}
        />
      )}

      {/* {showForm && !isNil(values?.meetingDateTime) ? (
        <CalendarInviteForm
          setShowSuccess={(passed) => setShowSuccess(passed)}
          showForm={showForm}
          setShowForm={setShowForm}
        />
      ) : (
        <TimePickerContent dates={dates} setShowForm={setShowForm} />
      )} */}
    </Box>
  );
};

const TimePickerContent = ({ show, dates, setShowForm, showForm, container, setInitLoad, initLoad }) => {
  useEffect(() => {
    setInitLoad(true);
  }, []);
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Slide in={show} container={container} direction={initLoad ? "right" : "down"}>
        <Box sx={{ ...centerCol, width: "100%", gap: "45px" }}>
          <CalendarSectionWrapper title={"What time zone are you in?"}>
            <TimezoneToggle />
          </CalendarSectionWrapper>
          <CalendarSectionWrapper title={"What time works best?"}>
            <CalendarTimeSlotPicker dates={dates} setShowForm={setShowForm} />
          </CalendarSectionWrapper>
        </Box>
      </Slide>
    </Box>
  );
};

const FormSuccessContent = ({ showSuccess }) => {
  const timezone = showSuccess?.clientSelectedTimezone;
  const selectedTime = showSuccess?.meetingDateTime;
  return (
    <Zoom in={true}>
      <Box
        sx={{
          ...centerCol,

          flexGrow: 1,
          padding: "30px",
          // padding: `30px 30px ${showForm ? "30px" : "0"} 30px`,
        }}
      >
        <SuccessContent sx={{ paddingTop: "0" }} />
        <Box
          column
          center
          sx={{
            ...centerCol,
          }}
        >
          <CalendarInviteTitleText variant={"h2"}>Meeting Confirmed</CalendarInviteTitleText>
          <Typography sx={{ textAlign: "center", fontSize: ".8rem", marginTop: "5px" }}>
            We look forward to talking to you soon, please review the following details below.
          </Typography>
          <Divider flexItem sx={{ width: "140px", margin: "20px auto 15px" }} />

          <Box sx={{ ...centerCol, gap: "30px", marginTop: "10px" }}>
            <CalendarInviteLabelAndValue
              column
              label={"Confirmation Email Sent To:"}
              delay="250ms"
              value={showSuccess?.emailAddress}
            />

            <CalendarInviteLabelAndValue
              column
              label={"Meeting Date:"}
              delay="400ms"
              value={formatISODate(showSuccess?.meetingDate)}
            />

            <CalendarInviteLabelAndValue
              column
              label={"Meeting Time:"}
              delay="550ms"
              value={`${getTimeFromISO(convertTimezone(selectedTime, timezone))} - ${timezoneOptions[timezone]}`}
            />
          </Box>
        </Box>
      </Box>
    </Zoom>
  );
};

const SectionHeader = ({ children }) => {
  return (
    <Box
      sx={{
        width: "100%",
        marginBottom: "20px",
      }}
    >
      {children}
    </Box>
  );
};

const SectionTitle = ({ children }) => {
  return (
    <Typography
      sx={{
        fontWeight: 400,
        textAlign: "left",

        fontSize: isMobile ? "1rem" : "1.2rem",
        fontFamily: "var(--mont) !important",
        color: "#73777a",
      }}
    >
      {children}
    </Typography>
  );
};

export default CalendarInviteMain;
