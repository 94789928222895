import { Box } from "@mui/material";
import React from "react";
import { Field, useFormState } from "react-final-form";
import { SelectFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/SelectFieldComponent";
import { TextareaFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/TextareaFieldComponent";
import { TextFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import RadioField from "../../../components/form/components/inputs/generic/RadioField/RadioField";
import RadioFieldTrueFalse from "../../../components/form/components/inputs/generic/RadioFieldTrueFalse";
import FormGroup from "../../../components/form/components/shared/FormGroup/FormGroup";
import WizardPageSubHeader from "../../../components/form/FormWizard/Components/WizardPageSubHeader";
import MuiGrid from "../../../components/shared/MuiGrid";
import GridItem from "../../../components/shared/MuiGrid/GridItem";
import { formFormatDollar } from "../../../helpers/parse/formFormatDollar";
import { parseNumber } from "../../../helpers/parse/parseNumber";
import { composeValidators } from "../../../helpers/validators/composeValidators";
import { VALIDATOR_MIN_CHAR, VALIDATOR_REQUIRE } from "../../../helpers/validators/inputValidators";
import DependentFields from "../../components/DependentFields";

// import Form from "../../../../UserAdmission/components/SignInForm/SignInForm";
// import "./CreditAuthFormStepOne.css";
const BorrowerLoanAppBackground = (props) => {
  const { values } = useFormState();

  const hasTaxLeins = values.hasTaxLiens === "Yes";
  const hasJudgements = values.hasJudgements === "Yes";
  const hasChargeOffs = values.hasChargeOffs === "Yes";
  return (
    <>
      <FormGroup noMargin>
        <MuiGrid>
          <GridItem size={12}>
            <RadioFieldTrueFalse
              noMargin
              name={"hasDerogatoryHousingEvents"}
              fieldLabel="Do you have any derogatory housing events such as foreclosure, deed-in-lieu or short sale or entered into a forbearance plan or bankruptcy in the past 4 years?"
              items={["Yes", "No"]}
            />
          </GridItem>

          <DependentFields name="hasDerogatoryHousingEvents" value="true">
            <GridItem size={12}>
              <Field
                validate={composeValidators(VALIDATOR_REQUIRE, VALIDATOR_MIN_CHAR(30))}
                minChar={30}
                name={"hasDerogatoryHousingEventsDescription"}
                fieldLabel="Please briefly describe any derogatory housing events that Flatiron Realty Capital should consider for purposes of underwriting due diligence"
                label="Please provide Description"
                component={TextareaFieldComponent}
              />
            </GridItem>
          </DependentFields>
          <GridItem size={12}>
            <RadioFieldTrueFalse
              isRequired
              name={"hasBeenConvictedOfFelony"}
              fieldLabel="Have you ever been convicted of a felony or have you been convicted of a misdemeanor involving fraud or embezzlement?"
              items={["Yes", "No"]}
            />
          </GridItem>

          <DependentFields name="hasBeenConvictedOfFelony" value="true">
            <GridItem size={12}>
              <Field
                name={"hasBeenConvictedOfFelonyDescription"}
                fieldLabel="Please briefly describe any felonies that Flatiron Realty Capital should consider for purposes of underwriting due diligence"
                label="Please provide Description"
                validate={composeValidators(VALIDATOR_REQUIRE, VALIDATOR_MIN_CHAR(30))}
                minChar={30}
                component={TextareaFieldComponent}
              />
            </GridItem>
          </DependentFields>
        </MuiGrid>
      </FormGroup>
      <FormGroup>
        <WizardPageSubHeader>Please inform us of the following:</WizardPageSubHeader>
        <FormGroup noMargin label="Do you have any Tax Leins?">
          <MuiGrid spacing={2}>
            <GridItem mobile size={hasTaxLeins ? 4 : 12}>
              <Field
                // fieldLabel="Tax Leins"
                label="Yes/No"
                name={"hasTaxLiens"}
                items={["Yes", "No"]}
                component={SelectFieldComponent}
              />
            </GridItem>

            {hasTaxLeins ? (
              <GridItem mobile size={4}>
                <Field
                  // parse={parseNumber}
                  // format={formFormatDollar}
                  dollar
                  fieldLabel="Amount"
                  name={"hasTaxLienAmount"}
                  label="Amount"
                  component={TextFieldComponent}
                />
              </GridItem>
            ) : null}

            {hasTaxLeins ? (
              <GridItem mobile size={4}>
                <Field
                  // fieldLabel="On Payment Plan?"
                  label="On Payment Plan?"
                  // label="Yes/No"
                  name={"hasTaxLiensPaymentPlan"}
                  items={["Yes", "No"]}
                  component={SelectFieldComponent}
                />
              </GridItem>
            ) : null}
          </MuiGrid>
        </FormGroup>
        <Box
          sx={{
            borderTop: "2px dashed #80808052",
            borderBottom: "2px dashed #80808052",
            paddingBottom: "25px",
            marginTop: "15px",
          }}
        >
          <FormGroup label="Do you have any Charge-offs or material liens?">
            <MuiGrid spacing={1}>
              <GridItem mobile size={hasChargeOffs ? 4 : 12}>
                <Field
                  // fieldLabel="Charge-offs or material liens"
                  label="Yes/No"
                  name={"hasChargeOffs"}
                  items={["Yes", "No"]}
                  component={SelectFieldComponent}
                />
              </GridItem>

              {hasChargeOffs ? (
                <GridItem mobile size={4}>
                  <Field
                    // parse={parseNumber}
                    // format={formFormatDollar}
                    dollar
                    fieldLabel="Amount"
                    name={"hasChargeOffsAmount"}
                    label="Amount"
                    component={TextFieldComponent}
                  />
                </GridItem>
              ) : null}

              {hasChargeOffs ? (
                <GridItem mobile size={4}>
                  <Field
                    // fieldLabel="On Payment Plan?"
                    label="On Payment Plan?"
                    name={"hasChargeOffsPaymentPlan"}
                    items={["Yes", "No"]}
                    component={SelectFieldComponent}
                  />
                </GridItem>
              ) : null}
            </MuiGrid>
          </FormGroup>
        </Box>
        <FormGroup label="Do you have any Judgements?">
          <MuiGrid spacing={1}>
            <GridItem mobile size={hasJudgements ? 4 : 12}>
              <Field
                // fieldLabel="Judgments"
                helperText="Including pending or unsettled"
                label="Yes/No"
                name={"hasJudgements"}
                items={["Yes", "No"]}
                component={SelectFieldComponent}
              />
            </GridItem>

            {hasJudgements ? (
              <GridItem mobile size={4}>
                <Field
                  // parse={parseNumber}
                  // format={formFormatDollar}
                  dollar
                  fieldLabel="Amount"
                  name={"hasJudgementsAmount"}
                  label="Amount"
                  component={TextFieldComponent}
                />
              </GridItem>
            ) : null}

            {hasJudgements ? (
              <GridItem mobile size={4}>
                <Field
                  label="On Payment Plan?"
                  // fieldLabel="On Payment Plan?"
                  // label="Yes/No"
                  name={"hasJudgementsPaymentPlan"}
                  items={["Yes", "No"]}
                  component={SelectFieldComponent}
                />
              </GridItem>
            ) : null}
          </MuiGrid>
        </FormGroup>
      </FormGroup>
    </>
  );
  // const { values } = useFormState();

  // const hasTaxLeins = values.hasTaxLiens === "Yes";
  // const hasJudgements = values.hasJudgements === "Yes";
  // const hasChargeOffs = values.hasChargeOffs === "Yes";
  // return (
  //   <>
  //     <FormGroup noMargin>
  //       <MuiGrid>
  //         <GridItem mobile size={12}>
  //           <RadioFieldTrueFalse
  //             noMargin
  //             name={"hasDerogatoryHousingEvents"}
  //             fieldLabel="Do you have any derogatory housing events such as foreclosure, deed-in-lieu or short sale or entered into a forbearance plan or bankruptcy in the past 4 years?"
  //             items={["Yes", "No"]}
  //           />
  //         </GridItem>

  //         <DependentFields name="hasDerogatoryHousingEvents" value="true">
  //           <GridItem mobile size={12}>
  //             <Field
  //               validate={composeValidators(
  //                 VALIDATOR_REQUIRE,
  //                 VALIDATOR_MIN_CHAR(30)
  //               )}
  //               minChar={30}
  //               name={"hasDerogatoryHousingEventsDescription"}
  //               fieldLabel="Please briefly describe any derogatory housing events that Flatiron Realty Capital should consider for purposes of underwriting due diligence"
  //               label="Please provide Description"
  //               component={TextareaFieldComponent}
  //             />
  //           </GridItem>
  //         </DependentFields>
  //         <GridItem mobile size={12}>
  //           <RadioFieldTrueFalse
  //             isRequired
  //             name={"hasBeenConvictedOfFelony"}
  //             fieldLabel="Have you ever been convicted of a felony or have you been convicted of a misdemeanor involving fraud or embezzlement?"
  //             items={["Yes", "No"]}
  //           />
  //         </GridItem>

  //         <DependentFields name="hasBeenConvictedOfFelony" value="true">
  //           <GridItem size={12}>
  //             <Field
  //               name={"hasBeenConvictedOfFelonyDescription"}
  //               fieldLabel="Please briefly describe any felonies that Flatiron Realty Capital should consider for purposes of underwriting due diligence"
  //               label="Please provide Description"
  //               validate={composeValidators(
  //                 VALIDATOR_REQUIRE,
  //                 VALIDATOR_MIN_CHAR(30)
  //               )}
  //               minChar={30}
  //               component={TextareaFieldComponent}
  //             />
  //           </GridItem>
  //         </DependentFields>
  //       </MuiGrid>
  //     </FormGroup>
  //     <FormGroup>
  //       <WizardPageSubHeader>
  //         Do you have any of the following:
  //       </WizardPageSubHeader>
  //       <MuiGrid spacing={2}>
  //         <GridItem size={hasTaxLeins ? 4 : 12}>
  //           <Field
  //             fieldLabel="Tax Leins"
  //             label="Yes/No"
  //             name={"hasTaxLiens"}
  //             items={["Yes", "No"]}
  //             component={SelectFieldComponent}
  //           />
  //         </GridItem>
  //         <GridItem size={4}>
  //           {hasTaxLeins ? (
  //             <>
  //               <Field
  //                 // parse={parseNumber}
  //                 // format={formFormatDollar}
  //                 dollar
  //                 fieldLabel="Amount"
  //                 name={"hasTaxLienAmount"}
  //                 label="Amount"
  //                 component={TextFieldComponent}
  //               />
  //             </>
  //           ) : null}
  //         </GridItem>
  //         <GridItem size={4}>
  //           {hasTaxLeins ? (
  //             <>
  //               <Field
  //                 fieldLabel="On Payment Plan?"
  //                 label="Yes/No"
  //                 name={"hasTaxLiensPaymentPlan"}
  //                 items={["Yes", "No"]}
  //                 component={SelectFieldComponent}
  //               />
  //             </>
  //           ) : null}
  //         </GridItem>
  //       </MuiGrid>
  //       <Box
  //         sx={{
  //           borderTop: "2px dashed #80808052",
  //           borderBottom: "2px dashed #80808052",
  //           paddingBottom: "25px",
  //           marginTop: "15px",
  //         }}
  //       >
  //         <MuiGrid spacing={2}>
  //           <GridItem size={hasChargeOffs ? 4 : 12}>
  //             <Field
  //               fieldLabel="Charge-offs or material liens"
  //               label="Yes/No"
  //               name={"hasChargeOffs"}
  //               items={["Yes", "No"]}
  //               component={SelectFieldComponent}
  //             />
  //           </GridItem>
  //           <GridItem size={4}>
  //             {hasChargeOffs ? (
  //               <>
  //                 <Field
  //                   // parse={parseNumber}
  //                   // format={formFormatDollar}
  //                   dollar
  //                   fieldLabel="Amount"
  //                   name={"hasChargeOffsAmount"}
  //                   label="Amount"
  //                   component={TextFieldComponent}
  //                 />
  //               </>
  //             ) : null}
  //           </GridItem>
  //           <GridItem size={4}>
  //             {hasChargeOffs ? (
  //               <>
  //                 <Field
  //                   fieldLabel="On Payment Plan?"
  //                   label="Yes/No"
  //                   name={"hasChargeOffsPaymentPlan"}
  //                   items={["Yes", "No"]}
  //                   component={SelectFieldComponent}
  //                 />
  //               </>
  //             ) : null}
  //           </GridItem>
  //         </MuiGrid>
  //       </Box>
  //       <MuiGrid spacing={2}>
  //         <GridItem size={hasJudgements ? 4 : 12}>
  //           <Field
  //             fieldLabel="Judgments"
  //             helperText="Including pending or unsettled"
  //             label="Yes/No"
  //             name={"hasJudgements"}
  //             items={["Yes", "No"]}
  //             component={SelectFieldComponent}
  //           />
  //         </GridItem>
  //         <GridItem size={4}>
  //           {hasJudgements ? (
  //             <Field
  //               // parse={parseNumber}
  //               // format={formFormatDollar}
  //               dollar
  //               fieldLabel="Amount"
  //               name={"hasJudgementsAmount"}
  //               label="Amount"
  //               component={TextFieldComponent}
  //             />
  //           ) : null}
  //         </GridItem>
  //         <GridItem size={4}>
  //           {hasJudgements ? (
  //             <Field
  //               fieldLabel="On Payment Plan?"
  //               label="Yes/No"
  //               name={"hasJudgementsPaymentPlan"}
  //               items={["Yes", "No"]}
  //               component={SelectFieldComponent}
  //             />
  //           ) : null}
  //         </GridItem>
  //       </MuiGrid>
  //     </FormGroup>
  //   </>
  // );
};

export default BorrowerLoanAppBackground;
// {/* <table id="borrower-background-table" className="table vertical">
// <thead>
//   <tr role="row">
//     <th
//       style={{
//         fontWeight: "500",
//         fontSize: ".8rem",
//         paddingLeft: "0px",
//         textAlign: "left",
//       }}
//       colspan={2}
//       role="columnheader"
//     >
//       {/* Do you have any of the following: */}
//     </th>
//     {/* <th colspan={1} role="columnheader"></th> */}
//     <th colspan={1} role="columnheader">
//       Amount of lien/judgment
//     </th>
//     <th colspan={1} role="columnheader">
//       On Payment Plan
//     </th>
//   </tr>
// </thead>
// <tbody>
//   <tr>
//     {/* <td>Tax Liens</td> */}
//     <td>
//       <RadioField
//         label="Tax Leins"
//         name={"hasTaxLiens"}
//         items={["Yes", "No"]}
//       />
//     </td>
//     <td>
//       {values.hasTaxLiens === "No" ? (
//         "—"
//       ) : (
//         <Field
//           parse={parseNumber}
//           // format={formFormatDollar}
//           dollar
//           name={"hasTaxLienAmount"}
//           label="Amount"
//           component={TextFieldComponent}
//         />
//       )}
//     </td>
//     <td>
//       {values.hasTaxLiens === "No" ? (
//         "—"
//       ) : (
//         <RadioField
//           vertical
//           name={"hasTaxLiensPaymentPlan"}
//           items={["Yes", "No"]}
//         />
//       )}
//     </td>
//   </tr>
//   <tr>
//     <td>Judgments (including pending or unsettled)</td>
//     <td>
//       <RadioField
//         isRequired
//         vertical
//         name={"hasJudgements"}
//         items={["Yes", "No"]}
//       />
//     </td>

//     <td>
//       {values.hasJudgements === "No" ? (
//         "—"
//       ) : (
//         <Field
//           parse={parseNumber}
//           // format={formFormatDollar}
//           dollar
//           name={"hasJudgementsAmount"}
//           label="Amount"
//           component={TextFieldComponent}
//         />
//       )}
//     </td>
//     <td>
//       {values.hasJudgements === "No" ? (
//         "—"
//       ) : (
//         <RadioField
//           vertical
//           name={"hasJudgementsPaymentPlan"}
//           items={["Yes", "No"]}
//         />
//       )}
//     </td>
//   </tr>
//   <tr>
//     <td>Charge-offs or material liens</td>
//     <td>
//       <RadioField
//         vertical
//         isRequired
//         name={"hasChargeOffs"}
//         items={["Yes", "No"]}
//       />
//     </td>

//     <td style={{ width: "200px" }}>
//       {values.hasChargeOffs === "No" ? (
//         "—"
//       ) : (
//         <Field
//           parse={parseNumber}
//           // format={formFormatDollar}
//           name={"hasChargeOffsAmount"}
//           dollar
//           label="Amount"
//           component={TextFieldComponent}
//         />
//       )}
//     </td>
//     <td>
//       {values.hasChargeOffs === "No" ? (
//         "—"
//       ) : (
//         <RadioField
//           vertical
//           isRequired
//           name={"hasChargeOffsPaymentPlan"}
//           items={["Yes", "No"]}
//         />
//       )}
//     </td>
//   </tr>
// </tbody>
// </table> */}
