import React from "react";
import FormField from "../../form/components/shared/FormField/FormField";
import FormLabel from "../../form/components/shared/FormLabel/FormLabel";
import "./TextOverflow.css";

const TextOverflow = (props) => {
  return (
    <div id={props.id} className="text-overflow">
      <FormField fieldLabel={props.title} noMargin={props.noMargin}>
        <div className="text-overflow-container">
          <div className="overflow-inner-wrapper">{props.children}</div>
        </div>
      </FormField>
    </div>
  );
};

export default TextOverflow;
