import { ChevronLeftRounded } from "@mui/icons-material";
import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { isNil } from "lodash";
import React, { useMemo, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import FinalFormSelectField from "../../../../components/form/Fields/FinalFormSelectField";

const TimezoneToggle = ({ props }) => {
  const { values } = useFormState();
  const [showOther, setShowOther] = useState(false);

  const timezone = useMemo(() => values?.clientSelectedTimezone, [values]);

  const { change } = useForm();

  const handleChange = (event, tz) => {
    if (isNil(tz)) {
      return;
    }
    if (tz === "Other") {
      setShowOther(true);
    }

    // const modifiedTimezone = tz;
    change("clientSelectedTimezone", tz);
  };

  const all_timezones = Intl.supportedValuesOf("timeZone");

  if (showOther) {
    return (
      <Box sx={{ display: "flex", width: "100%", alignItems: "center", gap: "15px" }}>
        <Button startIcon={<ChevronLeftRounded />} size="small" onClick={() => setShowOther(false)}>
          Back
        </Button>
        <FinalFormSelectField name={"clientSelectedTimezone"} label={"Select your timezone"} items={all_timezones} />
      </Box>
    );
  }

  return (
    <ToggleButtonGroup
      sx={{ width: "100%", marginBottom: "5px" }}
      color="primary"
      value={timezone}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton sx={{ textTransform: "capitalize", width: "100%", padding: "6px" }} value="America/Los_Angeles">
        Pacific
      </ToggleButton>
      <ToggleButton sx={{ textTransform: "capitalize", width: "100%", padding: "6px" }} value="America/Denver">
        Mountain
      </ToggleButton>
      <ToggleButton sx={{ textTransform: "capitalize", width: "100%", padding: "6px" }} value="America/Chicago">
        Central
      </ToggleButton>
      <ToggleButton sx={{ textTransform: "capitalize", width: "100%", padding: "6px" }} value="America/New_York">
        Eastern
      </ToggleButton>
      {/* <ToggleButton sx={{ textTransform: "capitalize", width: "100%", padding: "6px" }} value="Other">
        Other
      </ToggleButton>
      {showOther ? (
        <FinalFormSelectField name={"timezone"} label={"Select your timezone"} items={all_timezones} />
      ) : null} */}
    </ToggleButtonGroup>
  );
};
export default TimezoneToggle;
