import { isNil } from "lodash";

const _ = require("lodash");
export const getObjectValueFromStringPath = (
  object,
  pathName,
  defaultReturnValue
) => {
  // console.log(object, pathName);
  let value = _.get(object, pathName);
  if (
    defaultReturnValue &&
    (value === undefined || value === "undefined" || isNil(value))
  ) {
    return defaultReturnValue;
  }
  return value;
};
