import { DateCalendar, LocalizationProvider, PickersDay } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isEmpty, isNil } from "lodash";
import React from "react";
import { useForm, useFormState } from "react-final-form";

import { Box } from "@mui/material";
import { getDayNumFromDate } from "../helpers/getDayNumFromDate";
import { useCalendarHook } from "../useCalendarHook";
import { formatISO, getDay, isAfter, isPast, isThursday } from "date-fns";

const CalendarDatePicker = ({ dates }) => {
  const { getTimeSlotsForDay } = useCalendarHook();
  const { values } = useFormState();
  const { change } = useForm();

  const handleOnDateTimeChange = (date) => {
    console.log("date", date);
    const contentArea = document.getElementById("main-content");
    contentArea.scrollBy({ top: window.innerHeight / 2, behavior: "smooth" });
    if (!isNil(values?.meetingDateTime)) {
      console.log("111111");
      const init = {
        clientSelectedTimezone: values?.clientSelectedTimezone,
        meetingDate: date,
      };
      change(values, init);
      return;
    }
    console.log("22222");
    change("meetingDate", date);
  };

  function CustomDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const last_date = dates[dates.length - 1];
    const dayNum = getTimeSlotsForDay(dates, getDayNumFromDate(day));
    const dayExistsInDateList = isAfter(new Date(last_date), day);

    const isPassed = isPast(day);
    const showAvailable = !isEmpty(dayNum) && !outsideCurrentMonth && !isPassed && dayExistsInDateList;

    return (
      <PickersDay
        {...other}
        sx={{
          fontSize: "0.8rem",
          opacity: showAvailable ? 1 : 0.3,
          fontWeight: showAvailable ? "700 !important" : 400,
          background: showAvailable ? "#ffffff24" : "inherit",
          "&:hover": {
            background: "#0069ff87",
          },
        }}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexGrow: 1,
        // border: "var(--border)"
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateCalendar
          // renderDay={renderDayInPicker}
          onChange={handleOnDateTimeChange}
          slots={{
            day: CustomDay,
          }}
          slotProps={{
            textField: { variant: "filled", size: "small" },
          }}
          sx={{
            width: "100%",
            "div, p,span": {
              color: "#ffffff",
            },
            ".MuiDayCalendar-header": {
              borderBottom: "1px solid #ffffff29",
              ".MuiDayCalendar-weekDayLabel": {
                fontWeight: 700,
                fontSize: "0.8rem",
                opacity: 0.75,

                width: "44px",
                height: "44px",
              },
            },
            ".MuiPickersDay-today": { borderColor: "#ffffff !important" },
            ".MuiPickersDay-root": {
              width: "44px",
              height: "44px",
              "&.Mui-selected": {
                background: "#0069ff",
                "&:focus": {
                  background: "#0069ff91",
                },
                "&:hover": {
                  background: "#0069ff91",
                },
              },
            },
            ".MuiPickersCalendarHeader-root": {
              position: "relative",
              justifyContent: "center",
              ".MuiPickersCalendarHeader-switchViewButton": {
                display: "none",
              },
              ".MuiPickersCalendarHeader-labelContainer": {
                marginRight: 0,
                zIndex: 11111,
                ".MuiPickersCalendarHeader-label": {
                  fontWeight: 300,
                  fontFamily: "var(--mont) !important",
                  fontSize: "1rem",
                },
              },
            },
            //Month switchers
            ".MuiPickersArrowSwitcher-root": {
              position: "absolute",
              left: 0,
              width: "100%",
              // display: "flex",
              justifyContent: "center",
              // display: "none",
              // justifyContent: "space-between",
              ".MuiPickersArrowSwitcher-spacer": {
                width: "185px",
              },
              ".MuiButtonBase-root": {
                // background: "var(--lightBlue)",
                // fontSize: "1.2rem",
                background: "#ffffff24",
                fontSize: "1.2rem",
                color: "#ffffff",
                padding: "3px",
              },
            },
            ".MuiPickersToolbar-content .MuiTypography-root": {
              color: "#ffffff",
              fontSize: "1.4rem",
            },
            ".MuiDayCalendar-weekContainer , .MuiDayCalendar-header": {
              // gap: "2px",
            },
            ".MuiDayCalendar-weekContainer": {
              ".MuiButtonBase-root": {
                color: "#ffffff",
              },
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default CalendarDatePicker;
