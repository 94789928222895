import React from "react";
import { useForm } from "react-final-form";
import FormField from "../../../shared/FormField/FormField";
// import { Radio } from "final-form-material-ui";
import { Checkbox, FormControl, FormControlLabel, FormLabel } from "@mui/material";
import { useFormState } from "react-final-form";

// import "./RadioField.css";
import { getObjectValueFromStringPath } from "../../../../../../helpers/getObjectValueFromStringPath";
import { isNil } from "lodash";
import { Stack } from "@mui/system";
import MuiGrid from "../../../../../shared/MuiGrid";
import GridItem from "../../../../../shared/MuiGrid/GridItem";

const MultiSelectFinalForm = (props) => {
  const { values, errors, submitFailed } = useFormState();

  const showError = props.isRequired && submitFailed && getObjectValueFromStringPath(values, props.name) === undefined;

  return (
    <FormField fieldLabel={props.fieldLabel} id="radio-field">
      <FormControl fullWidth variant="filled">
        <FormLabel id="demo-radio-buttons-group-label">{props.label}</FormLabel>

        {props.columns ? (
          <Stack direction={"row"} sx={{ flexWrap: "wrap" }}>
            {props.items.map((o, i) => (
              <CheckboxField key={o.name} parentName={props.name} value={o} />
            ))}
          </Stack>
        ) : (
          <Stack sx={{ paddingTop: "10px" }}>
            {props.items.map((o) => (
              <CheckboxField key={o.name} parentName={props.name} value={o} />
            ))}
          </Stack>
        )}
      </FormControl>

      {showError && <span className="error">Required</span>}
    </FormField>
  );
};

export default MultiSelectFinalForm;

const CheckboxField = (props) => {
  const { values } = useFormState();
  const { change } = useForm();
  const { parentName, value } = props;
  const { name, label } = value;
  let parentValue = isNil(getObjectValueFromStringPath(values, parentName))
    ? []
    : getObjectValueFromStringPath(values, parentName);
  const doesExist = parentValue.some((e) => e.name === props.value.name);

  const checkIfChecked = () => {
    if (doesExist) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnChange = () => {
    let updated = [];
    if (doesExist) {
      updated = parentValue.filter((i) => i.name !== name);
    } else {
      updated = [...parentValue, value];
    }
    change(parentName, updated);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={handleOnChange}
          checked={checkIfChecked()}
          sx={{
            fontWeight: 600,
            marginLeft: "10px",
          }}
          size="medium"
        />
      }
      label={props.value.label}
    />
  );
};
