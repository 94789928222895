import React from "react";
import AnimatedCheckmark from "../AnimatedCheckmark/AnimatedCheckMark";
import SvgWrapper from "../SvgComponents/SvgWrapper/SvgWrapper";
import "./SuccessContent.css";

const SuccessContent = ({ message, sx }) => {
  return (
    <div className="success-content" style={sx}>
      <div className="header-success-content">
        <SvgWrapper center className="checkmark">
          <AnimatedCheckmark />
        </SvgWrapper>
        {message ? <p className="success-message">{message}</p> : null}
      </div>
    </div>
  );
};

export default SuccessContent;
