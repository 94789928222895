import React from "react";

import LandingPage from "../../components/LandingPage/LandingPage";

// import "./BorrowerCreditAuthWorkspace.css";
import BackgroundShape from "../../components/shared/BackgroundShape";
import SuccessContent from "../../components/shared/SuccessContent";
import BorrowerCreditAuthFormWizard from "../../forms/BorrowerCreditAuth/BorrowerCreditAuthFormWizard";

const BorrowerCreditAuthScreen = (props) => {
  const [isSuccess, setIsSuccess] = React.useState(false);

  const title = isSuccess ? "Thanks for your submission!" : "Credit & Background Authorization";
  const description = isSuccess
    ? "Your Credit Authorization was successfully submitted and we will reach out to you shortly."
    : "Please fill out the following fields to submit a Flatiron Credit & Background Authorization.";

  return (
    <>
      <BackgroundShape />
      <LandingPage id="guest-credit-auth" className={isSuccess && "success"} title={title} description={description}>
        {isSuccess ? <SuccessContent /> : <BorrowerCreditAuthFormWizard onSuccess={() => setIsSuccess(true)} />}
      </LandingPage>
    </>
  );
};

export default BorrowerCreditAuthScreen;
