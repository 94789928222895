import React, { useState } from "react";

import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { Field } from "react-final-form";

import { PhoneOutlined } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { Stack } from "@mui/system";
import Form from "../components/form/Form/Form";
import { TextFieldComponent } from "../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import FormGroup from "../components/form/components/shared/FormGroup/FormGroup";
import FormSubmitButton from "../components/form/components/shared/FormSubmitButton/FormSubmitButton";
import ErrorMessage from "../components/shared/ErrorMessage";
import MuiGrid from "../components/shared/MuiGrid";
import GridItem from "../components/shared/MuiGrid/GridItem";
import { parsePhoneNumber } from "../helpers/parse/parsePhoneNumber";
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from "../helpers/validators/inputValidators";
import { useAxiosHook } from "../hooks/useAxiosHook";
import FileUploadField from "../components/form/components/inputs/generic/FileUploadField/FileUploadField";

const ContactAuctionBorrowerForm = ({ onSuccess }) => {
  const [error, setError] = useState(null);

  const { axiosError, axiosPostIsLoading, axiosPostRequest } = useAxiosHook();

  const signUp = async (state) => {
    console.log("🚀   state", state);

    const params = {
      name: "Contact Auction Borrower",
      path: "contact_auction_borrower",
      payload: state,
      onSuccessFn: onSuccess,
    };

    console.log(params);
    // onSuccess()
    await axiosPostRequest(params);
  };

  return (
    <Form
      id="create-organization"
      // hideButton
      // customFormTypes={{ userCategory: "broker" }}
      isLoading={axiosPostIsLoading}
      onClick={signUp}
      // formSpy
    >
      <FormGroup noMargin>
        <MuiGrid spacing={1}>
          <GridItem mobile size={6}>
            <Field
              validate={VALIDATOR_REQUIRE}
              name={"firstName"}
              label={`First Name`}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem mobile size={6}>
            <Field validate={VALIDATOR_REQUIRE} name={"lastName"} label={`Last Name`} component={TextFieldComponent} />
          </GridItem>
          <GridItem size={12}>
            <Field
              validate={VALIDATOR_EMAIL}
              name={"emailAddress"}
              label={`Email Address`}
              icon={<EmailOutlined />}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              validate={VALIDATOR_REQUIRE}
              name={"phoneNumber"}
              icon={<PhoneOutlined />}
              parse={parsePhoneNumber}
              label={`Phone Number`}
              component={TextFieldComponent}
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>

      {/* <FormSubmitButton isLoading={axiosPostIsLoading} buttonText={"Submit"} /> */}
      <ErrorMessage show={error} message={error} />
    </Form>
  );
};

const ErrorSuggestions = (props) => {
  return (
    <>
      <ErrorMessage
        bold
        style={{ marginTop: "15px" }}
        show={true}
        message={"Password not strong enough, here's what we suggest."}
      />
      <Stack direction={"column"} color="error">
        {props.errors.map((err) => (
          // <ErrorMessage style={{ background: "red" }} show={true} message={err} />
          <Chip
            sx={{
              color: "var(--red)",
              // display: 'flex',
              width: "min-content",
              margin: "5px auto 0",
              background: "#d32f2f24",
            }}
            label={err}
          />
        ))}
      </Stack>
    </>
  );
};

export default ContactAuctionBorrowerForm;
