import React from "react";
import { Field, useFormState } from "react-final-form";
import { TextFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import FileUploadField from "../../../components/form/components/inputs/generic/FileUploadField/FileUploadField";
import FormGroup from "../../../components/form/components/shared/FormGroup/FormGroup";
import MuiGrid from "../../../components/shared/MuiGrid";
import GridItem from "../../../components/shared/MuiGrid/GridItem";
import { formFormatDollar } from "../../../helpers/parse/formFormatDollar";
import { parseNumber } from "../../../helpers/parse/parseNumber";
import { VALIDATOR_REQUIRE } from "../../../helpers/validators/inputValidators";

// import Form from "../../../../UserAdmission/components/SignInForm/SignInForm";
// import "./CreditAuthFormStepOne.css";

const BorrowerLoanAppLiquidity = (props) => {
  const { values } = useFormState();

  const checkOtherFieldsAreSelected = (value) => {
    if (
      values.liquidityPersonalBankAccounts !== undefined ||
      values.liquidityBusinessBankAccounts !== undefined ||
      values.liquidityIraBrokerageAccounts !== undefined ||
      values.liquidityCashValueOfLifeInsurance !== undefined ||
      values.liquidityOther !== undefined
    ) {
      return undefined;
    } else {
      return VALIDATOR_REQUIRE(value);
    }
  };
  return (
    <>
      <FormGroup label="Borrower Liquidity" noMargin>
        <MuiGrid>
          <GridItem size={12}>
            <Field
              noMargin
              validate={(value) => checkOtherFieldsAreSelected(value)}
              name="liquidityPersonalBankAccounts"
              fieldLabel="Tell us about your assets in each of the following account types:"
              label="Personal Bank Accounts"
              dollar
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              validate={(value) => checkOtherFieldsAreSelected(value)}
              name="liquidityBusinessBankAccounts"
              label="Business Bank Accounts"
              helperText="Where borrower is a member/manager of the entity"
              // format={formFormatDollar}
              dollar
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              validate={(value) => checkOtherFieldsAreSelected(value)}
              name="liquidityIraBrokerageAccounts"
              label="401k / IRA / Brokerage Accounts"
              // format={formFormatDollar}
              dollar
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              validate={(value) => checkOtherFieldsAreSelected(value)}
              name="liquidityCashValueOfLifeInsurance"
              label="Cash value of a life insurance or annuity policy"
              // format={formFormatDollar}
              dollar
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              validate={(value) => checkOtherFieldsAreSelected(value)}
              name="liquidityOther"
              label="Other liquidity value"
              // format={formFormatDollar}
              dollar
              component={TextFieldComponent}
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
    </>
  );
};

export default BorrowerLoanAppLiquidity;
