import React from "react";

import LandingPage from "../../components/LandingPage/LandingPage";

// import "./BorrowerCreditAuthWorkspace.css";
import BackgroundShape from "../../components/shared/BackgroundShape";
import SuccessContent from "../../components/shared/SuccessContent";
import BorrowerCreditAuthFormWizard from "../../forms/BorrowerCreditAuth/BorrowerCreditAuthFormWizard";
import ContactAuctionBorrowerForm from "../../forms/ContactAuctionBorrowerForm";
import { Box } from "@mui/material";

const BorrowerContactUsScreen = (props) => {
  const [isSuccess, setIsSuccess] = React.useState(false);

  const title = isSuccess ? "Thanks for your submission!" : "Borrower Auction Contact Request";
  const description = isSuccess
    ? "Flatiron Account Executive will reach out to you shortly."
    : "Please fill out the following fields and a Flatiron Account Executive will reach out to you.";

  return (
    <>
      <BackgroundShape />
      <LandingPage id="contact-us" className={isSuccess && "success"} title={title} description={description} auction>
        {isSuccess ? (
          <SuccessContent />
        ) : (
          <Box sx={{ paddingTop: "10px" }}>
            <ContactAuctionBorrowerForm onSuccess={() => setIsSuccess(true)} />
          </Box>
        )}
      </LandingPage>
    </>
  );
};

export default BorrowerContactUsScreen;
