import React from "react";

import HeaderImage from "../../../assets/images/auction-home.png";
import LandingPage from "../../../components/LandingPage/LandingPage";
// import "./BorrowerCreditAuthWorkspace.css";

import { Box, Stack, Typography } from "@mui/material";

import FormWizard from "../../../components/form/FormWizard/FormWizard";
import SuccessContent from "../../../components/shared/SuccessContent";
import { formStateWithQueryParams } from "../../../helpers/formStateWithQueryParams";
import { useFormsHook } from "../../../hooks/useFormsHook";
import AuctionBorrowerExperience from "./formSteps/AuctionBorrowerExperience";
import AuctionBorrowerUpcomingAuctions from "./formSteps/AuctionBorrowerUpcomingAuctions";
import AuctionConfirmation from "./formSteps/AuctionConfirmation";
import AuctionContactInfo from "./formSteps/AuctionContactInfo";
import AuctionLiquidity from "./formSteps/AuctionLiquidity";

const AuctionComScreen = (props) => {
  const [isSuccess, setIsSuccess] = React.useState(false);
  const { isLoading, submitAuctionForm } = useFormsHook();

  const handleFormSubmit = async (formState) => {
    const params = {
      payload: formStateWithQueryParams(formState),
      onSuccessFn: () => setIsSuccess(true),
    };

    await submitAuctionForm(params);
  };

  const formSteps = [
    {
      stepName: "Background",
      stepComponent: <AuctionContactInfo />,
    },
    {
      stepName: "Upcoming Auctions",
      stepComponent: <AuctionBorrowerUpcomingAuctions />,
    },
    {
      stepName: "Experience",
      stepComponent: <AuctionBorrowerExperience />,
    },
    {
      stepName: "Liquidity",
      stepComponent: <AuctionLiquidity />,
    },

    {
      stepName: "Confirmation",
      stepComponent: <AuctionConfirmation />,
    },
  ];

  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);

  //   // const token = params.get("token");
  //   const entries = params.entries();
  //   // console.log("PARAMS", entries);
  //   let paramsString = ``;
  //   for (const entry of entries) {
  //     paramsString += `${entry[0]}=${entry[1]}&`;
  //   }

  //   const endpointParams = paramsString.replace(/.$/, "");
  //   let endpoint_get = `https://ironlinc-dev.azurewebsites.net?${endpointParams}`;
  //   let endpoint_post = `https://ironlinc-dev.azurewebsites.net?actionCategory=auctionPreApproval&${endpointParams}`;

  //   console.log("Endpoint: GET", endpoint_get);
  //   console.log("Endpoint: POST", endpoint_post);
  // }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "44vh",
          img: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
        }}
        component={"section"}
        id="auction-landing-page"
      >
        <img src={HeaderImage} alt="birds eye view of a suburban neighbourhood" srcSet="" />
      </Box>
      <Box
        component={"section"}
        className="landing-page"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LandingPage
          card
          auction
          title={isSuccess ? "Your Submission Was Successful" : "Auction Investor Financing Preapproval"}
          description={
            isSuccess ? null : "Please fill out the following fields to get preapproved Auction property financing."
          }
        >
          {isSuccess ? (
            <>
              <SuccessContent />
              <Stack>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#707070",
                    fontSize: "0.9rem",
                    lineHeight: " 24px",
                    marginTop: " 5px",
                  }}
                >
                  A member of our team will reach out you via phone.
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "#707070",
                    fontSize: "0.9rem",
                    lineHeight: " 24px",
                    marginTop: " 5px",
                  }}
                >
                  Pre-approvals within 24 hours*
                </Typography>
              </Stack>
            </>
          ) : (
            <FormWizard
              formSteps={formSteps}
              id="auction-preapproval-form"
              initialValues={{
                allowPhoneNumberContact: "Yes",
              }}
              isLoading={isLoading}
              onSubmit={handleFormSubmit}
              readOnly={props.readOnly}
              muiStepper
              alt
              formType={"flatironSiteCreditAuth"}
              submitButtonText={"Submit Preapproval Application and Credit Authorization"}
              // clickable
              // formSpy
            />
          )}
        </LandingPage>
      </Box>
    </Box>
  );
};

export default AuctionComScreen;
