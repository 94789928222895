const isDebug = true;

// const isDev = process.env.en === "localhost" ? true : false;

// const devURL = `${isDev ? "http" : "https"}://20.228.154.238:8001`;
const devURL = `http://20.228.154.238:8001`;
const prodURL = "https://ironlinc-dev.azurewebsites.net";
// const prodURL = "https://auth.flatironrealtycapital.com";
export const apiDomains = prodURL;
// export const apiDomainsLogin =  process.env.NODE_ENV === "production" ? prodURL : devURL;;
// export const apiDomains =
//   process.env.NODE_ENV === "production" ? prodURL : devURL;

export const apiDomainsProd = prodURL;
