import * as React from "react";

import { ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { inputTheme } from "./assets/styles/MuiStyles";
import "./assets/styles/global.css";

import { useSelector } from "react-redux";
import SnackbarAlert from "./components/shared/SnackbarAlert/SnackbarAlert";

import NoResultsDialog from "./components/shared/NoResultsDialog";
import { globalAlertClose } from "./redux/actions/globalAlertActions";
import UnauthenticatedView from "./views/UnauthenticatedView";

const App = () => {
  const globalAlert = useSelector((state) => state.globalAlert);
  const dispatch = useDispatch();

  const handleCloseSnackbar = () => {
    dispatch(globalAlertClose());
  };

  return (
    <ThemeProvider theme={inputTheme}>
      <UnauthenticatedView />

      <SnackbarAlert
        open={globalAlert.show}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message={globalAlert.message}
        sx={{ bottom: "40px !important" }}
        anchorPosition="left"
        variant="filled"
        isGlobal
        type={globalAlert.type}
      />
      {/* <NoResultsDialog open={globalAlert.noResultsShow} onClose={handleCloseSnackbar} /> */}
    </ThemeProvider>
  );
};

export default App;
