import React from "react";
import FinalFormTextField from "../../../../components/form/Fields/FinalFormTextField";
import FileUploadField from "../../../../components/form/components/inputs/generic/FileUploadField/FileUploadField";
import { VALIDATOR_REQUIRE } from "../../../../helpers/validators/inputValidators";
import { useFormState } from "react-final-form";
import MuiGrid from "../../../../components/shared/MuiGrid";
import GridItem from "../../../../components/shared/MuiGrid/GridItem";
import FormGroup from "../../../../components/form/components/shared/FormGroup/FormGroup";

const AuctionLiquidity = (props) => {
  const { values } = useFormState();

  const checkOtherFieldsAreSelected = (value) => {
    if (
      values.liquidityPersonalBankAccounts !== undefined ||
      values.liquidityBusinessBankAccounts !== undefined ||
      values.liquidityIraBrokerageAccounts !== undefined ||
      values.liquidityCashValueOfLifeInsurance !== undefined ||
      values.liquidityOther !== undefined
    ) {
      return undefined;
    } else {
      return VALIDATOR_REQUIRE(value);
    }
  };
  return (
    <>
      <FormGroup label="Your Liquidity">
        <MuiGrid>
          <GridItem size={12}>
            <FinalFormTextField
              validate={(value) => checkOtherFieldsAreSelected(value)}
              name="liquidityPersonalBankAccounts"
              fieldLabel="Tell us about your assets in each of the following account types:"
              label="Personal Bank Accounts"
              dollar
            />
          </GridItem>
          <GridItem size={12}>
            <FinalFormTextField
              validate={(value) => checkOtherFieldsAreSelected(value)}
              name="liquidityBusinessBankAccounts"
              label="Business Bank Accounts"
              helperText="Where borrower is a member/manager of the entity"
              dollar
            />
          </GridItem>
          <GridItem size={12}>
            <FinalFormTextField
              validate={(value) => checkOtherFieldsAreSelected(value)}
              name="liquidityIraBrokerageAccounts"
              label="401k / IRA / Brokerage Accounts"
              dollar
            />
          </GridItem>
          <GridItem size={12}>
            <FinalFormTextField
              validate={(value) => checkOtherFieldsAreSelected(value)}
              name="liquidityCashValueOfLifeInsurance"
              label="Cash value of a life insurance or annuity policy"
              dollar
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <FormGroup label="Other Liquidity Sources">
        <MuiGrid>
          <GridItem mobile size={6}>
            <FinalFormTextField name="liquidityFromPartners" label="Funds sourced from partners" dollar />
          </GridItem>
          <GridItem mobile size={6}>
            <FinalFormTextField name="liquidityGiftFunds" label="Gift funds" dollar />
          </GridItem>
        </MuiGrid>
      </FormGroup>
      <FormGroup label="Upload bank or account statements">
        <MuiGrid>
          <GridItem size={12}>
            <FileUploadField name={"liquidityOtherDocuments"} />
          </GridItem>
        </MuiGrid>
      </FormGroup>
    </>
  );
};

export default AuctionLiquidity;
