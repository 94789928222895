import { Box, Typography } from "@mui/material";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactFinalForm from "../../../components/form/ReactFinalForm";

import FlatIronLogo from "../../../components/shared/FlatIronLogo/FlatIronLogo";
import FullPageLoader from "../../../components/shared/FullPageLoader";
import MuiLoader from "../../../components/shared/MuiLoader";
import { clearFormData } from "../../../redux/actions/formDataActions";
import CalendarInviteMain from "./CalendarInviteMain";
import { getClientTimezone } from "./helpers/getClientTimezone";
import { getStartOfDay } from "./helpers/getStartOfDay";
import { useCalendarHook } from "./useCalendarHook";
import CalendarInviteAside from "./CalendarInviteAside";
import CalendarLogo from "./components/CalendarLogo";
import { isMobile } from "react-device-detect";

const CalendarInviteScreen = (props) => {
  const { error, isLoading, availableDates, getUserDetails, data, internalUserName, isValidPath } = useCalendarHook();
  const today = getStartOfDay();
  const dispatch = useDispatch();
  const clientTimezone = getClientTimezone();
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (isValidPath) {
      getUserDetails({});
    }

    return () => dispatch(clearFormData());
  }, []);

  if (isNil(data) && !error) {
    return <FullPageLoader />;
  }

  if (!isValidPath) {
    return (
      <Box
        sx={{
          display: "flex",
          padding: "8px",
          background: "#fbfcfd",
          width: "100%",
          height: "100%",
          overflowY: "auto",
          justifyContent: "center",
        }}
      >
        <Typography>Invalid URL Path</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          display: "flex",

          background: "#fbfcfd",
          width: "100vw",
          height: "100vh",
          // height: "100%",
          zIndex: -1,
        }}
      ></Box>
      <Box
        id="main-content"
        sx={{
          // display: "flex",
          // flexDirection: "column",
          // justifyContent: "center",
          // alignItems: "center",

          width: "100%",
          padding: "30px 10px 50px",
          background: "#fbfcfd",

          // height: "100%",
          overflowY: "auto",
          form: {
            width: "100%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        <CalendarLogo width={isMobile ? "125px" : "225px"} sx={{ margin: "0 auto 20px" }} />
        <ReactFinalForm
          hideButton
          // formSpy
          initialValues={{
            clientSelectedTimezone: "America/New_York",
            meetingDate: today,
            clientTimezone: clientTimezone,
          }}
        >
          <Box
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 2px 1px",
              borderRadius: "4px",
              background: "#ffffff",
              maxWidth: "1060px",
              minHeight: showSuccess ? "unset" : "550px",
              display: "flex",
              overflow: "hidden",
              flexBasis: showSuccess ? "580px" : "900px",
              flexGrow: showSuccess ? 0 : 1,
              flexWrap: "wrap",
            }}
          >
            {isLoading || error ? (
              <GuardClauseUi isLoading={isLoading} error={error} />
            ) : (
              <>
                {showSuccess ? null : <CalendarInviteAside data={data} dates={availableDates} />}
                <CalendarInviteMain dates={availableDates} showSuccess={showSuccess} setShowSuccess={setShowSuccess} />
              </>
            )}
          </Box>
        </ReactFinalForm>
      </Box>
    </>
  );
  // return (
  //   <Box
  //     sx={{
  //       display: "flex",
  //       alignItems: "flex-start",
  //       padding: "30px 8px 50px",
  //       background: "#fbfcfd",
  //       width: "100%",
  //       // height: "100%",
  //       overflowY: "auto",
  //       form: {
  //         width: "100%",
  //         margin: "0 auto",
  //         display: "flex",
  //         justifyContent: "center",
  //       },
  //     }}
  //   >
  //     <Box
  //       sx={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}
  //     >
  //       <CalendarLogo width="225px" sx={{ margin: "0 auto 20px" }} />
  //       <ReactFinalForm
  //         hideButton
  //         // formSpy
  //         initialValues={{
  //           clientSelectedTimezone: "America/New_York",
  //           meetingDate: today,
  //           clientTimezone: clientTimezone,
  //         }}
  //       >
  //         <Box
  //           sx={{
  //             boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 2px 1px",
  //             borderRadius: "4px",
  //             background: "#ffffff",
  //             maxWidth: "1060px",
  //             minHeight: showSuccess ? "unset" : "550px",
  //             display: "flex",
  //             overflow: "hidden",
  //             flexBasis: showSuccess ? "680px" : "900px",
  //             flexGrow: showSuccess ? 0 : 1,
  //             flexWrap: "wrap",
  //           }}
  //         >
  //           {isLoading || error ? (
  //             <GuardClauseUi isLoading={isLoading} error={error} />
  //           ) : (
  //             <>
  //               {showSuccess ? null : <CalendarInviteAside data={data} dates={availableDates} />}
  //               <CalendarInviteMain dates={availableDates} showSuccess={showSuccess} setShowSuccess={setShowSuccess} />
  //             </>
  //           )}
  //         </Box>
  //       </ReactFinalForm>
  //     </Box>
  //   </Box>
  // );
};

const GuardClauseUi = ({ isLoading, error }) => {
  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          padding: "30px",
          background: "#fbfcfd",
          width: "100%",
          height: "100%",

          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ textAlign: "center" }}>
          Unable to get meeting dates, please make sure you are using the correct URL.
        </Typography>
      </Box>
    );
  }
  if (isLoading) {
    return <MuiLoader />;
  }
};

export default CalendarInviteScreen;
