import React from "react";
import LandingPage from "../../components/LandingPage/LandingPage";
import BackgroundShape from "../../components/shared/BackgroundShape";
import SuccessContent from "../../components/shared/SuccessContent";
import BorrowerLoanApplicationFormWizard from "../../forms/BorrowerLoanApplication/BorrowerLoanApplicationFormWizard";

const BorrowerLoanApplicationScreen = (props) => {
  const [showError, setShowError] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const title = isSuccess ? "Thanks for your submission!" : "Flatiron Loan Application";
  const description = isSuccess
    ? "We will process your application reach out to you shortly."
    : "Please fill out the following fields to submit an application for a loan.";
  return (
    <>
      <BackgroundShape />

      <LandingPage
        id="loan-application"
        cardStyle={{ paddingTop: "20px" }}
        className={isSuccess && "success"}
        title={title}
        description={description}
      >
        {isSuccess ? <SuccessContent /> : <BorrowerLoanApplicationFormWizard onSuccess={() => setIsSuccess(true)} />}
      </LandingPage>
    </>
  );
};

export default BorrowerLoanApplicationScreen;
