import { AddOutlined } from "@mui/icons-material";
import React from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { buttonGhost } from "../../../assets/styles/SharedInlineStyles";
import { PasswordFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/PasswordFieldComponent";
import { SelectFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/SelectFieldComponent";
import { TextFieldComponent } from "../../../components/form/components/inputs/finalFormComponents/TextFieldComponent";
import AddressAutofillField from "../../../components/form/components/inputs/generic/AddressAutofillField/AddressAutofillField";
import DobField from "../../../components/form/components/inputs/generic/DobField";
import RadioField from "../../../components/form/components/inputs/generic/RadioField/RadioField";
import RadioFieldTrueFalse from "../../../components/form/components/inputs/generic/RadioFieldTrueFalse";
import FormGroup from "../../../components/form/components/shared/FormGroup/FormGroup";
import WizardPageHeader from "../../../components/form/WizardPageHeader";

import MuiGrid from "../../../components/shared/MuiGrid";
import GridItem from "../../../components/shared/MuiGrid/GridItem";
import { formFormatPercent } from "../../../helpers/parse/formFormatPercent";
import { parseNumberMax } from "../../../helpers/parse/parseNumberMax";
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from "../../../helpers/validators/inputValidators";
import MultipleItemCard from "../../components/MultipleItemCard";
import { citizenshipOptions, estimatedCreditScore, yesNo } from "../../_formQuestions/selectOptions";

// import Form from "../../../../UserAdmission/components/SignInForm/SignInForm";
// import "./BorrowerDetailsAndContactInfo.css";

const BorrowerLoanAppContactInfo = (props) => {
  const { values } = useFormState();

  const { mutators, change } = useForm();

  const normalizePhone = (value) => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length <= 3) return onlyNums;
    if (onlyNums.length <= 7) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`;
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
  };

  const parseNumberMax = (value) => {
    // console.log(value);
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length <= 9) return onlyNums;
    if (onlyNums.length > 9) {
      return onlyNums.slice(0, 9);
    }
  };

  const addAdditionalFieldHandler = (name) => {
    mutators.push("guarantors", {});
  };
  // useEffect(() => {
  //   if (values.guarantors === null || values.guarantors === undefined) {
  //     addAdditionalFieldHandler();
  //   }
  // }, []);

  const handleAdditionalGuarantorChange = (e) => {
    let val = e.target.value;
    // console.log("val", val);
    if (val === "Yes") {
      addAdditionalFieldHandler();
    }
    if (val === "No") {
      change("guarantors", []);
    }
  };

  return (
    <>
      <FormGroup noMargin fieldLabel="Your Information">
        <MuiGrid>
          <GridItem size={6}>
            <Field validate={VALIDATOR_REQUIRE} name={`firstName`} label="First Name" component={TextFieldComponent} />
          </GridItem>
          <GridItem size={6}>
            <Field validate={VALIDATOR_REQUIRE} name={`lastName`} label="Last Name" component={TextFieldComponent} />
          </GridItem>
          <GridItem size={12}>
            <Field
              validate={VALIDATOR_REQUIRE}
              name={`ssn`}
              label="Social Security Number"
              parse={parseNumberMax}
              component={PasswordFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              name="emailAddress"
              label="Email Address"
              validate={VALIDATOR_EMAIL}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              name="phoneNumber"
              label="Phone Number"
              validate={VALIDATOR_REQUIRE}
              parse={normalizePhone}
              component={TextFieldComponent}
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>

      <AddressAutofillField
        isRequired
        name={`primaryAddress`}
        label="Primary Home Address"
        value={values.primaryAddress}
      />
      <DobField />

      <MuiGrid>
        <GridItem size={12}>
          <RadioField
            isRequired
            name={`citizenshipStatus`}
            fieldLabel="Citizenship Status"
            items={citizenshipOptions}
          />
        </GridItem>
        {props.index >= 1 && (
          <GridItem size={12}>
            <RadioField
              isRequired
              name={`additionalGuarantorIsExperienced`}
              fieldLabel="Is additional guarantor an experienced real estate/property investor?"
              items={yesNo}
            />
          </GridItem>
        )}
        <GridItem size={12}>
          <RadioFieldTrueFalse
            isRequired
            name={"isMarried"}
            items={["Married", "Not Married"]}
            fieldLabel="What is your marital status?"
          />
        </GridItem>
        <GridItem size={12}>
          <Field
            name={`estimatedCreditScore`}
            fieldLabel="(If not a foreign national) Your estimated credit score"
            label="Estimated Credit Score"
            component={SelectFieldComponent}
            validate={VALIDATOR_REQUIRE}
            items={estimatedCreditScore}
            // items={[
            //   "660-679",
            //   "680-699 ",
            //   "700-719",
            //   "720-739",
            //   "740-759",
            //   "760-779",
            //   "≥ 780",
            // ]}
          />
        </GridItem>
        <GridItem size={12}>
          <RadioField
            isRequired
            name={"hasAdditionalGuarantors"}
            items={yesNo}
            fieldLabel="Are there additional guarantors?"
            onChange={handleAdditionalGuarantorChange}
          />
        </GridItem>
      </MuiGrid>

      {values.guarantors !== undefined && values?.guarantors?.length >= 1 && (
        <FormGroup>
          <WizardPageHeader>Additional Guarantors</WizardPageHeader>
          <FieldArray name={"guarantors"}>
            {({ fields }) =>
              fields.map((name, index) => <GuarantorCard name={name} index={index} fields={fields} {...props} />)
            }
          </FieldArray>
          <div className="add-additional-field-wrapper center">
            <button
              style={buttonGhost}
              onClick={() => addAdditionalFieldHandler()}
              className="add-additional-button"
              type="button"
            >
              <span>Add Additional Guarantor</span>
              <AddOutlined />
            </button>
          </div>
        </FormGroup>
      )}
    </>
  );
};

const GuarantorCard = (props) => {
  const { name, index, fields } = props;

  const normalizePhone = (value) => {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length <= 3) return onlyNums;
    if (onlyNums.length <= 7) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 7)}`;
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
  };

  return (
    <MultipleItemCard
      title={"Guarantor"}
      onRemove={() => fields.remove(index)}
      index={index}
      unitsPathName={`guarantors`}
      // totalItems={values.subjectProperties.length}
    >
      <FormGroup noMargin fieldLabel="Guarantor Information">
        <MuiGrid>
          <GridItem size={6}>
            <Field
              validate={VALIDATOR_REQUIRE}
              name={`${name}.firstName`}
              label="First Name"
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={6}>
            <Field
              validate={VALIDATOR_REQUIRE}
              name={`${name}.lastName`}
              label="Last Name"
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              name={`${name}.phoneNumber`}
              label="Phone Number"
              validate={VALIDATOR_REQUIRE}
              parse={normalizePhone}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              name={`${name}.emailAddress`}
              label="Email Address"
              validate={VALIDATOR_EMAIL}
              component={TextFieldComponent}
            />
          </GridItem>
          <GridItem size={12}>
            <RadioField
              isRequired
              name={`${name}.additionalGuarantorIsExperienced`}
              fieldLabel="Is additional guarantor an experienced real estate/property investor?"
              items={yesNo}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              name={`${name}.estimatedCreditScore`}
              fieldLabel={"Select estimated credit score range of guarantor"}
              label="Estimated Credit Score"
              component={SelectFieldComponent}
              validate={VALIDATOR_REQUIRE}
              items={["660-679", "680-699 ", "700-719", "720-739", "740-759", "760-779", "≥ 780"]}
            />
          </GridItem>
          <GridItem size={12}>
            <Field
              validate={VALIDATOR_REQUIRE}
              parse={parseNumberMax}
              name={`${name}.percentageEntityOwnership`}
              fieldLabel={"What is the guarantors' entity ownership percentage?"}
              label="Ownership Percentage"
              format={formFormatPercent}
              component={TextFieldComponent}
            />
          </GridItem>
        </MuiGrid>
      </FormGroup>

      {/* <FileUploadField
        name={fieldNames.photoId}
        fieldLabel="Upload a Photo ID"
        docGroup={fileDocumentSchema.borrowerDocs.name}
        docType={fileDocumentSchema.borrowerDocs.types.photoId.name}
        maxFiles={1}
      /> */}
    </MultipleItemCard>
  );
};

export default BorrowerLoanAppContactInfo;
