import { ChevronLeftRounded } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { isNil } from "lodash";
import React from "react";

const BackButton = ({ onClick, sx, children }) => {
  if (isNil(children)) {
    return (
      <Tooltip title="Back to home" arrow>
        <IconButton
          sx={{
            background: "#dfe7ed",
            color: "var(--blue)",
            borderRadius: "50px",
            padding: "4px",
            ...sx,
          }}
          onClick={onClick}
        >
          <ChevronLeftRounded />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <Button
      sx={sx}
      onClick={onClick}
      startIcon={
        <ChevronLeftRounded
          sx={{
            background: "#dfe7ed",
            color: "var(--blue)",
            borderRadius: "50px",
          }}
        />
      }
    >
      {children}
    </Button>
  );
};

export default BackButton;
