export const createSelectItemsEmployees = (employees) => {
  let listOptions = [];

  if (employees === undefined || employees === null) {
    return [];
  }

  employees.forEach((e) => {
    listOptions.push(e.fullName);
  });
  listOptions.sort((a, b) => a.localeCompare(b));

  return listOptions;
};
